$(document).ready(() => {
	let data = window.configCalc;

	//Object.entries(data).forEach(entry => {
	//	const obj = entry[1];
	//
	//	obj.value = obj.value || obj.min;
	//});

	new Vue({
		el: '#calc',
		data,
		computed: {
			creditMonthPay() {
				return Math.round(this.creditSumm.value * ((this.creditRate.value / 100 / 12) / (1 - Math.pow(1 + this.creditRate.value / 100 / 12, -(this.creditTerm.value)))));
			},
			creditOverallPay() {
				return Math.round(this.creditMonthPay * this.creditTerm.value)
			}
		},
		methods: {
			finish() {
				document.querySelector('#credit-month-pay').value = this.creditMonthPay;
				document.querySelector('#credit-overall-pay').value = this.creditOverallPay;
				document.querySelector('#credit-sum').value = this.creditSumm.value;
				document.querySelector('#credit-term').value = this.creditTerm.value;
				document.querySelector('#credit-rate').value = this.creditRate.value;
				GG.togglePopup('popup-request-calc');
			}
		}
	});
});


